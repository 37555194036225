<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<el-card class="box-card order-list">
			<div slot="header" class="clearfix"><span>我的福利卡</span></div>
			<el-tabs v-model="orderStatus" @tab-click="handleClick">
				<el-tab-pane label="全部福利卡" name="3"></el-tab-pane>
				<el-tab-pane label="已兑换" name="1"></el-tab-pane>
				<el-tab-pane label="未兑换" name="0"></el-tab-pane>
				<el-tab-pane label="已过期" name="2"></el-tab-pane>
			</el-tabs>
			<div v-loading="loading">
				<nav>
					<li>福利卡信息</li>
					<li>金额</li>
					<li>状态</li>
					<li>操作</li>
				</nav>
				<div class="list" v-if="orderList.length > 0">
					<div class="item" v-for="(orderItem, orderIndex) in orderList" :key="orderIndex">
						<div class="head" style="display: flex;align-items: center;">
							<span class="order-no" style="flex:1">发放日期：{{orderItem.fafang_date}}</span>
							<span class="order-no" style="flex:1">兑换有效期至：{{orderItem.youxiao_time}}</span>
							<span class="order-no" style="flex:1">兑换日期：{{orderItem.duihuan_time}}</span>
						</div>
						<ul v-for="(goodsItem, goodsIndex) in 1" :key="goodsIndex">
							<li>
								<div class="img-wrap">
									<img :src="'https://shuaichang.cgwzm.com/'+orderItem.taocan_pic" />
								</div>
								<div class="info-wrap">
									<h5>{{orderItem.ka_name}}</h5>
									<!-- <span>规格：规格值</span> -->
								</div>
							</li>
							<li>
								¥{{orderItem.ka_money}}元
							</li>
							<li>
								<span
									class="ns-text-color">{{orderItem.is_use==0?'未兑换':orderItem.is_use==1?'已兑换':orderItem.is_use==0?'已过期':''}}</span>
							</li>
							<li>
								<el-link :underline="false" v-if='orderItem.is_use==0'
									@click="duihuan(orderItem)">立即兑换</el-link>
							</li>
						</ul>
					</div>
				</div>
				<div v-else-if="!loading && orderList.length == 0" class="empty-wrap">暂无数据</div>
			</div>
			<div class="pager">
				<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
					:current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange"
					@current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import {
		apiOrderList,
		fuliKa,
		yfuliKa
	} from '@/api/order/order';
	import orderMethod from '@/utils/orderMethod';
	export default {
		name: 'order_list',
		components: {},
		data: () => {
			return {
				orderStatus: '3',
				loading: true,
				orderList: [],
				currentPage: 1,
				pageSize: 10,
				total: 0,
				yes: true
			};
		},
		mixins: [orderMethod],
		created() {
			this.orderStatus = this.$route.query.status || '3';
			this.getOrderList();
		},
		computed: {
			...mapGetters(['defaultGoodsImage'])
		},
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
		methods: {
			duihuan(e) {
				let tipsText = '是否兑换此福利卡';
				this.$confirm(tipsText, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					yfuliKa({
							ka_id: e.id
						})
						.then(res => {
							console.log(res)
							if (res.code == 0) {
								this.$message.success(res.message);
								this.$router.push('/member/account')
							} else {
								console.log(2)
								this.$message.error(res.message);
							}
						})
						.catch(res => {});
				}).catch(() => {});
			},
			handleClick(tab, event) {
				this.currentPage = 1;
				this.orderStatus = tab.name;
				this.refresh();
			},
			getOrderList() {
				fuliKa({
						page: this.currentPage,
						page_size: this.pageSize,
						is_use: this.orderStatus,
						type: 1
					})
					.then(res => {
						let list = [];
						if (res.code == 0) {
							console.log(res)
							list = res.kainfo;
							// this.total = res.data.count;
						}
						this.orderList = list;
						this.loading = false;
					})
					.catch(res => {
						this.loading = false;
					});
			},
			handlePageSizeChange(size) {
				this.pageSize = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			refresh() {
				this.loading = true;
				this.getOrderList();
			},
			operation(action, orderData) {
				let index = this.status;
				switch (action) {
					case 'orderPay': // 支付
						this.orderPay(orderData);
						break;
					case 'orderClose': //关闭
						this.orderClose(orderData.order_id, () => {
							this.refresh();
						});
						break;
					case 'memberTakeDelivery': //收货
						this.orderDelivery(orderData.order_id, () => {
							this.refresh();
						});
						break;
					case 'trace': //查看物流
						this.$router.push({
							path: '/member/logistics',
							query: {
								order_id: orderData.order_id
							}
						});
						break;
					case 'memberOrderEvaluation': //评价
						this.$router.pushToTab({
							path: '/evaluate',
							query: {
								order_id: orderData.order_id
							}
						});
						break;
				}
			},
			orderDetail(data) {
				this.$router.push({
					path: '/member/welfare_detail?data=' + JSON.stringify(data)
				});
				return
				switch (parseInt(data.order_type)) {
					case 2:
						// 自提订单
						this.$router.push({
							path: '/member/welfare_detail',
							query: {
								order_id: data.order_id
							}
						});
						break;
					case 3:
						// 本地配送订单
						this.$router.push({
							path: '/member/order_detail_local_delivery',
							query: {
								order_id: data.order_id
							}
						});
						break;
					case 4:
						// 虚拟订单
						this.$router.push({
							path: '/member/order_detail_virtual',
							query: {
								order_id: data.order_id
							}
						});
						break;
					default:
						this.$router.push({
							path: '/member/order_detail',
							query: {
								order_id: data.order_id
							}
						});
						break;
				}
			},
			imageError(orderIndex, goodsIndex) {
				this.orderList[orderIndex].order_goods[goodsIndex].sku_image = this.defaultGoodsImage;
			}
		}
	};
</script>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.order-list {
		nav {
			overflow: hidden;
			padding: 10px 0;
			background: #fff;
			margin-bottom: 10px;
			border-bottom: 1px solid #eeeeee;

			li {
				float: left;

				&:nth-child(1) {
					width: 45%;
				}

				&:nth-child(2) {
					margin-left: 5%;
					width: 10%;
				}

				&:nth-child(3) {
					width: 15%;
				}

				&:nth-child(4) {
					width: 20%;
				}

				&:nth-child(5) {
					width: 15%;
				}

				&:nth-child(6) {
					width: 10%;
				}
			}
		}

		.list {
			.item {
				margin-bottom: 20px;
				border: 1px solid #eeeeee;
				border-top: 0;

				.head {
					padding: 8px 10px;
					background: #f7f7f7;
					font-size: 12px;

					.create-time {
						margin-right: 10px;
					}

					border-bottom: 1px solid #eeeeee;

					a {
						margin: 0 10px 0 20px;
					}

					.order-type {
						margin-left: 30px;
					}
				}
			}

			ul {
				background-color: #fff;
				padding: 10px;
				overflow: hidden;

				li {
					float: left;
					line-height: 60px;

					&:nth-child(1) {
						width: 45%;
						line-height: inherit;

						.img-wrap {
							width: 60px;
							height: 60px;
							float: left;
							margin-right: 10px;
							cursor: pointer;
						}

						.info-wrap {
							margin-left: 70px;

							h5 {
								font-weight: normal;
								font-size: $ns-font-size-base;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
								margin-right: 10px;
								display: inline-block;
								cursor: pointer;

								&:hover {
									color: $base-color;
								}
							}

							span {
								font-size: $ns-font-size-sm;
								color: #9a9a9a;
							}
						}
					}

					&:nth-child(2) {
						width: 10%;
						margin-left: 5%;

						a {
							display: block;
						}
					}

					&:nth-child(3) {
						width: 15%;
					}

					&:nth-child(4) {
						width: 20%;
					}

					&:nth-child(5) {
						width: 15%;
						line-height: 30px;

						a {
							display: block;
						}
					}

					&:nth-child(6) {
						width: 10%;
						line-height: initial;

						button {
							margin-left: 0;
							margin-bottom: 10px;

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		.empty-wrap {
			text-align: center;
			padding: 10px;
		}
	}
</style>